var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "knowledge" }, [
    _vm.answer != ""
      ? _c("div", { staticClass: "bottom" }, [
          _vm._m(0),
          _c("div", { staticClass: "knowledge-box" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("预估主要治疗药物和费用")
            ]),
            _vm.answer == "AAA"
              ? _c("div", { staticClass: "tijian" }, [
                  _vm._v("针对保乳术后、Her2阴性、激素受体阴性的患者")
                ])
              : _vm._e(),
            _vm.answer == "BAA"
              ? _c("div", { staticClass: "tijian" }, [
                  _vm._v("针对全切术后，Her2阴性，激素受体阴性的患者")
                ])
              : _vm._e(),
            _vm.answer == "AAB"
              ? _c("div", { staticClass: "tijian" }, [
                  _vm._v("针对保乳术后，Her2阴性，激素受体阳性的患者")
                ])
              : _vm._e(),
            _vm.answer == "BAB"
              ? _c("div", { staticClass: "tijian" }, [
                  _vm._v("针对全切术后，Her2阴性，激素受体阳性的患者")
                ])
              : _vm._e(),
            _vm.answer == "BBA"
              ? _c("div", { staticClass: "tijian" }, [
                  _vm._v("针对全切术后，Her2阳性，激素受体阴性的患者")
                ])
              : _vm._e(),
            _vm.answer == "ABA"
              ? _c("div", { staticClass: "tijian" }, [
                  _vm._v("针对保乳术后，Her2阳性，激素受体阴性的患者")
                ])
              : _vm._e(),
            _vm.answer == "ABB"
              ? _c("div", { staticClass: "tijian" }, [
                  _vm._v("针对保乳术后，Her2阳性，激素受体阳性的患者")
                ])
              : _vm._e(),
            _vm.answer == "BBB"
              ? _c("div", { staticClass: "tijian" }, [
                  _vm._v("针对全切术后，Her2阳性，激素受体阳性的患者")
                ])
              : _vm._e(),
            _vm._m(1),
            _vm._m(2)
          ]),
          _vm.answer == "AAA"
            ? _c("div", { staticClass: "zhuliao" }, [
                _c("div", { staticClass: "tips" }, [
                  _vm._v("预估疾病进展及未来主要治疗药物和费用：")
                ]),
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    " 一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。 "
                  )
                ]),
                _c("div", { staticClass: "jieshao" }, [
                  _vm._v(
                    " 针对保乳术后，Her2阴性，激素受体阴性的患者可能用药及费用如下： "
                  )
                ]),
                _vm._m(3),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show = !_vm.show
                      }
                    }
                  },
                  [_vm._v("可能的其他治疗方案详情>>")]
                ),
                _vm.show
                  ? _c("div", [
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("化疗方案见下表：")
                      ]),
                      _vm._m(4),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("放疗方案见下表：")
                      ]),
                      _vm._m(5),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ])
                    ])
                  : _vm._e(),
                _vm._m(6)
              ])
            : _vm._e(),
          _vm.answer == "BAA"
            ? _c("div", { staticClass: "zhuliao" }, [
                _c("div", { staticClass: "tips" }, [
                  _vm._v("预估疾病进展及未来主要治疗药物和费用：")
                ]),
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    " 一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。 "
                  )
                ]),
                _c("div", { staticClass: "jieshao" }, [
                  _vm._v(
                    " 针对全切术后，Her2阴性，激素受体阴性的患者可能用药及费用如下： "
                  )
                ]),
                _vm._m(7),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show = !_vm.show
                      }
                    }
                  },
                  [_vm._v("可能的其他治疗方案详情>>")]
                ),
                _vm.show
                  ? _c("div", [
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("化疗方案见下表：")
                      ]),
                      _vm._m(8),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("放疗方案见下表：")
                      ]),
                      _vm._m(9),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ])
                    ])
                  : _vm._e(),
                _vm._m(10)
              ])
            : _vm._e(),
          _vm.answer == "AAB"
            ? _c("div", { staticClass: "zhuliao" }, [
                _c("div", { staticClass: "tips" }, [
                  _vm._v("预估疾病进展及未来主要治疗药物和费用：")
                ]),
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    " 一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。 "
                  )
                ]),
                _c("div", { staticClass: "jieshao" }, [
                  _vm._v(
                    " 针对保乳术后，Her2阴性，激素受体阳性的患者可能用药及费用如下： "
                  )
                ]),
                _vm._m(11),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show = !_vm.show
                      }
                    }
                  },
                  [_vm._v("可能的其他治疗方案详情>>")]
                ),
                _vm.show
                  ? _c("div", [
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("化疗方案见下表：")
                      ]),
                      _vm._m(12),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("放疗方案见下表：")
                      ]),
                      _vm._m(13),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show1 = !_vm.show1
                      }
                    }
                  },
                  [_vm._v("放化疗完成后，其他后续内分泌方案见详情>>")]
                ),
                _vm.show1
                  ? _c("div", [
                      _c("div", { staticClass: "jieshao" }, [_vm._v("绝经前")]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("初始治疗：")
                      ]),
                      _vm._m(14),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("延长治疗：")
                      ]),
                      _vm._m(15),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ]),
                      _c("div", { staticClass: "jieshao" }, [_vm._v("绝经后")]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("初始治疗：")
                      ]),
                      _vm._m(16),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("延长治疗：")
                      ]),
                      _vm._m(17)
                    ])
                  : _vm._e(),
                _vm._m(18)
              ])
            : _vm._e(),
          _vm.answer == "BAB"
            ? _c("div", { staticClass: "zhuliao" }, [
                _c("div", { staticClass: "tips" }, [
                  _vm._v("预估疾病进展及未来主要治疗药物和费用：")
                ]),
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    " 一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。 "
                  )
                ]),
                _c("div", { staticClass: "jieshao" }, [
                  _vm._v(
                    " 针对全切术后，Her2阴性，激素受体阳性的患者可能用药及费用如下： "
                  )
                ]),
                _vm._m(19),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show = !_vm.show
                      }
                    }
                  },
                  [_vm._v("可能的其他治疗方案详情>>")]
                ),
                _vm.show
                  ? _c("div", [
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("化疗方案见下表：")
                      ]),
                      _vm._m(20),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("放疗方案见下表：")
                      ]),
                      _vm._m(21),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show1 = !_vm.show1
                      }
                    }
                  },
                  [_vm._v("放化疗完成后，其他后续内分泌方案见详情>>")]
                ),
                _vm.show1
                  ? _c("div", [
                      _c("div", { staticClass: "jieshao" }, [_vm._v("绝经前")]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("初始治疗：")
                      ]),
                      _vm._m(22),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("延长治疗：")
                      ]),
                      _vm._m(23),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ]),
                      _c("div", { staticClass: "jieshao" }, [_vm._v("绝经后")]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("初始治疗：")
                      ]),
                      _vm._m(24),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("延长治疗：")
                      ]),
                      _vm._m(25)
                    ])
                  : _vm._e(),
                _vm._m(26)
              ])
            : _vm._e(),
          _vm.answer == "BBA"
            ? _c("div", { staticClass: "zhuliao" }, [
                _c("div", { staticClass: "tips" }, [
                  _vm._v("预估疾病进展及未来主要治疗药物和费用：")
                ]),
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    " 一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是较大压力。 "
                  )
                ]),
                _vm._m(27),
                _vm._m(28),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show = !_vm.show
                      }
                    }
                  },
                  [_vm._v("可能的其他治疗方案详情>>")]
                ),
                _vm.show
                  ? _c("div", [
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("化疗方案见下表：")
                      ]),
                      _c("div", { staticClass: "table-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "table",
                            staticStyle: { width: "580px" }
                          },
                          [
                            _c("table", [
                              _vm._m(29),
                              _c("tr", [
                                _c("td", { attrs: { rowspan: "2" } }, [
                                  _vm._v("腋窝淋巴结阳性")
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "AC-THP",
                                        center: _vm.ACTHPTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.5万元")])
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TCbHP",
                                        center: _vm.TCbHPTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.3万元")])
                              ]),
                              _c("tr", [
                                _vm._m(30),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "AC-TH",
                                        center: _vm.ACTHTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("2.9万元")])
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TCbH",
                                        center: _vm.TCbHTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.0万元")])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("腋窝淋巴结阳性且T<2cm")]),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TC+H",
                                        center: _vm.TCbTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("2.7万元")])
                              ])
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("放疗方案见下表：")
                      ]),
                      _vm._m(31),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ])
                    ])
                  : _vm._e(),
                _vm._m(32),
                _vm._m(33),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _vm._m(34)
              ])
            : _vm._e(),
          _vm.answer == "ABA"
            ? _c("div", { staticClass: "zhuliao" }, [
                _c("div", { staticClass: "tips" }, [
                  _vm._v("预估疾病进展及未来主要治疗药物和费用：")
                ]),
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    " 一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是很大的压力。 "
                  )
                ]),
                _vm._m(35),
                _vm._m(36),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show = !_vm.show
                      }
                    }
                  },
                  [_vm._v("可能的其他治疗方案详情>>")]
                ),
                _vm.show
                  ? _c("div", [
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("化疗方案见下表：")
                      ]),
                      _c("div", { staticClass: "table-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "table",
                            staticStyle: { width: "580px" }
                          },
                          [
                            _c("table", [
                              _vm._m(37),
                              _c("tr", [
                                _c("td", { attrs: { rowspan: "2" } }, [
                                  _vm._v("腋窝淋巴结阳性")
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "AC-THP",
                                        center: _vm.ACTHPTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.5万元")])
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TCbHP",
                                        center: _vm.TCbHPTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.3万元")])
                              ]),
                              _c("tr", [
                                _vm._m(38),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "AC-TH",
                                        center: _vm.ACTHTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("2.9万元")])
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TCbH",
                                        center: _vm.TCbHTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.0万元")])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("腋窝淋巴结阳性且T<2cm")]),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TC+H",
                                        center: _vm.TCbTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("2.7万元")])
                              ])
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("放疗方案见下表：")
                      ]),
                      _vm._m(39),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ])
                    ])
                  : _vm._e(),
                _vm._m(40),
                _vm._m(41),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _vm._m(42)
              ])
            : _vm._e(),
          _vm.answer == "ABB"
            ? _c("div", { staticClass: "zhuliao" }, [
                _c("div", { staticClass: "tips" }, [
                  _vm._v("预估疾病进展及未来主要治疗药物和费用：")
                ]),
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    " 一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是很大的压力。 "
                  )
                ]),
                _vm._m(43),
                _vm._m(44),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show = !_vm.show
                      }
                    }
                  },
                  [_vm._v("可能的其他治疗方案详情>>")]
                ),
                _vm.show
                  ? _c("div", [
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("化疗方案见下表：")
                      ]),
                      _c("div", { staticClass: "table-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "table",
                            staticStyle: { width: "580px" }
                          },
                          [
                            _c("table", [
                              _vm._m(45),
                              _c("tr", [
                                _c("td", { attrs: { rowspan: "2" } }, [
                                  _vm._v("腋窝淋巴结阳性")
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "AC-THP",
                                        center: _vm.ACTHPTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.5万元")])
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TCbHP",
                                        center: _vm.TCbHPTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.3万元")])
                              ]),
                              _c("tr", [
                                _vm._m(46),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "AC-TH",
                                        center: _vm.ACTHTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("2.9万元")])
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TCbH",
                                        center: _vm.TCbHTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.0万元")])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("腋窝淋巴结阳性且T<2cm")]),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TC+H",
                                        center: _vm.TCbTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("2.7万元")])
                              ])
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("放疗方案见下表：")
                      ]),
                      _vm._m(47),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show1 = !_vm.show1
                      }
                    }
                  },
                  [_vm._v("放化疗完成后，其他后续内分泌方案见详情>>")]
                ),
                _vm.show1
                  ? _c("div", [
                      _vm._m(48),
                      _vm._m(49),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v(" 延长治疗： ")
                      ]),
                      _vm._m(50),
                      _vm._m(51),
                      _vm._m(52),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v(" 延长治疗： ")
                      ]),
                      _vm._m(53)
                    ])
                  : _vm._e(),
                _vm._m(54),
                _vm._m(55),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _vm._m(56)
              ])
            : _vm._e(),
          _vm.answer == "BBB"
            ? _c("div", { staticClass: "zhuliao" }, [
                _c("div", { staticClass: "tips" }, [
                  _vm._v("预估疾病进展及未来主要治疗药物和费用：")
                ]),
                _c("div", { staticClass: "center" }, [
                  _vm._v(
                    " 一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是很大的压力 "
                  )
                ]),
                _vm._m(57),
                _vm._m(58),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show = !_vm.show
                      }
                    }
                  },
                  [_vm._v("可能的其他治疗方案详情>>")]
                ),
                _vm.show
                  ? _c("div", [
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("化疗方案见下表：")
                      ]),
                      _c("div", { staticClass: "table-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "table",
                            staticStyle: { width: "580px" }
                          },
                          [
                            _c("table", [
                              _vm._m(59),
                              _c("tr", [
                                _c("td", { attrs: { rowspan: "2" } }, [
                                  _vm._v("腋窝淋巴结阳性")
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "AC-THP",
                                        center: _vm.ACTHPTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.5万元")])
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TCbHP",
                                        center: _vm.TCbHPTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.3万元")])
                              ]),
                              _c("tr", [
                                _vm._m(60),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "AC-TH",
                                        center: _vm.ACTHTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("2.9万元")])
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TCbH",
                                        center: _vm.TCbHTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("3.0万元")])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("腋窝淋巴结阳性且T<2cm")]),
                                _c(
                                  "td",
                                  [
                                    _c("popover", {
                                      attrs: {
                                        title: "TC+H",
                                        center: _vm.TCbTips
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v("2.7万元")])
                              ])
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ]),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v("放疗方案见下表：")
                      ]),
                      _vm._m(61),
                      _c("div", { staticClass: "huadong" }, [
                        _vm._v("滑动查看更多内容")
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function($event) {
                        _vm.show1 = !_vm.show1
                      }
                    }
                  },
                  [_vm._v("放化疗完成后，其他后续内分泌方案见详情>>")]
                ),
                _vm.show1
                  ? _c("div", [
                      _vm._m(62),
                      _vm._m(63),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v(" 延长治疗： ")
                      ]),
                      _vm._m(64),
                      _vm._m(65),
                      _vm._m(66),
                      _c("div", { staticClass: "jieshao" }, [
                        _vm._v(" 延长治疗： ")
                      ]),
                      _vm._m(67)
                    ])
                  : _vm._e(),
                _vm._m(68),
                _vm._m(69),
                _c("div", { staticClass: "huadong" }, [
                  _vm._v("滑动查看更多内容")
                ]),
                _vm._m(70)
              ])
            : _vm._e(),
          _vm._m(71)
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wenti-img" }, [
      _c("img", { attrs: { src: require("../../assets/images/xjqr.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mianze" }, [
      _c("div", { staticClass: "tips" }, [_vm._v("免责条款：")]),
      _c("div", { staticStyle: { "line-height": ".4rem" } }, [
        _vm._v(
          " 如下治疗方案及药物等信息来源于国家临床指南，但仅作为预估治疗费用的依据，不得作为患者诊疗方案的建议。本平台不承担诊疗行为相关的任何责任。 "
        )
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "yuhou" }, [
      _c("div", { staticClass: "tips" }, [_vm._v("关于预后：")]),
      _c("div", { staticClass: "center" }, [
        _vm._v(
          " 如您有足够资金完成系统性治疗，那么您带病生存周期就更长，带病生存质量也更好。 "
        )
      ]),
      _c("div", { staticClass: "list-box" }, [
        _c("div", { staticClass: "tips" }, [
          _vm._v("根据疾病分期，且您有足够资金完成系统性治疗，则生存期为：")
        ]),
        _c("div", { staticClass: "list" }, [
          _c("ul", [
            _c("li", [
              _c("span", [_vm._v("0 - 1")]),
              _vm._v("期患者：五年生存率99％")
            ]),
            _c("li", [
              _c("span", [_vm._v("2 - 3")]),
              _vm._v("期患者：五年生存率是86％")
            ]),
            _c("li", [
              _c("span", [_vm._v("4期")]),
              _vm._v("五年相对生存率是27％")
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "img1" }, [
        _c("img", { attrs: { src: require(".././../assets/images/tg1.png") } })
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", { attrs: { rowspan: "3" } }, [_vm._v("AC-T")]),
            _c("td", [_vm._v("多柔比星（多美素）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11200")]),
            _c("td", [_vm._v("44800")]),
            _c("td", [_vm._v("否，全部自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("环磷酰胺（安道生）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("440")]),
            _c("td", [_vm._v("1760")]),
            _c("td", [_vm._v("是，医保甲类")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("序惯紫杉醇（克艾力）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11900")]),
            _c("td", [_vm._v("47600")]),
            _c("td", [_vm._v("是，医保乙类")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("ddAC-ddT")]),
            _c("td", [_vm._v("密集型蒽环类+环磷酰胺序贯密集型紫杉醇")]),
            _c("td", [_vm._v("14")]),
            _c("td", [_vm._v("2.4万元")]),
            _c("td", [_vm._v("9.6万元")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("AC")]),
            _c("td", [_vm._v("蒽环类+环磷酰胺")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("1.2万元")]),
            _c("td", [_vm._v("4.8万元")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("TC")]),
            _c("td", [_vm._v("多西他赛+环磷酰胺")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("1.2万元")]),
            _c("td", [_vm._v("4.8万元")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("导管原位癌")]),
            _c("td", [_vm._v("全乳放疗±瘤床加量")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("浸润癌，腋窝淋巴结阴性")]),
            _c("td", [
              _vm._v(
                "1、全乳放疗（常规或大分割（首选））±瘤床加量； 2、部分乳腺短程照射（APBI）"
              )
            ])
          ]),
          _c("tr", [
            _c("td", [_vm._v("浸润癌，腋窝淋巴结阳性，已清扫")]),
            _c("td", [_vm._v("全乳放疗+瘤床加量+区域淋巴结放疗")])
          ]),
          _c("tr", [
            _c("td", [
              _vm._v("浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫")
            ]),
            _c("td", [_vm._v("全乳放疗（乳房高位切线野）+瘤床加量")])
          ]),
          _c("tr", [
            _c("td", [
              _vm._v("浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫")
            ]),
            _c("td")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img2" }, [
      _c("img", { attrs: { src: require(".././../assets/images/tg2.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", { attrs: { rowspan: "3" } }, [_vm._v("AC-T")]),
            _c("td", [_vm._v("多柔比星（多美素）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11200")]),
            _c("td", [_vm._v("44800")]),
            _c("td", [_vm._v("否，全部自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("环磷酰胺（安道生）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("440")]),
            _c("td", [_vm._v("1760")]),
            _c("td", [_vm._v("是，医保甲类")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("序惯紫杉醇（克艾力）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11900")]),
            _c("td", [_vm._v("47600")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("ddAC-ddT")]),
            _c("td", [_vm._v("密集型蒽环类+环磷酰胺序贯密集型紫杉醇")]),
            _c("td", [_vm._v("14")]),
            _c("td", [_vm._v("2.4万元")]),
            _c("td", [_vm._v("9.6万元")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("AC")]),
            _c("td", [_vm._v("蒽环类+环磷酰胺")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("1.2万元")]),
            _c("td", [_vm._v("4.8万元")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("TC")]),
            _c("td", [_vm._v("多西他赛+环磷酰胺")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("1.2万元")]),
            _c("td", [_vm._v("4.8万元")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("腋窝淋巴结清扫术后")]),
              _c("p", [_vm._v("1、T3—T4")]),
              _c("p", [_vm._v("2、腋窝淋巴结阳性")])
            ]),
            _c("td", [_vm._v("胸壁+区域淋巴结放疗")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("前哨淋巴结阳性，未行腋窝淋巴结清扫")]),
            _c("td", [_vm._v("胸壁+包括腋窝在内的区域淋巴结放疗")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img2" }, [
      _c("img", { attrs: { src: require(".././../assets/images/tg2.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", { attrs: { rowspan: "3" } }, [_vm._v("AC-T")]),
            _c("td", [_vm._v("多柔比星（多美素）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11200")]),
            _c("td", [_vm._v("44800")]),
            _c("td", [_vm._v("否，全部自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("环磷酰胺（安道生）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("440")]),
            _c("td", [_vm._v("1760")]),
            _c("td", [_vm._v("是，医保甲类")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("序惯紫杉醇（克艾力）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11900")]),
            _c("td", [_vm._v("47600")]),
            _c("td", [_vm._v("是，医保乙类")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("ddAC-ddT")]),
            _c("td", [_vm._v("密集型蒽环类+环磷酰胺序贯密集型紫杉醇")]),
            _c("td", [_vm._v("14")]),
            _c("td", [_vm._v("2.4万元")]),
            _c("td", [_vm._v("9.6万元")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("AC")]),
            _c("td", [_vm._v("蒽环类+环磷酰胺")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("1.2万元")]),
            _c("td", [_vm._v("4.8万元")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("TC")]),
            _c("td", [_vm._v("多西他赛+环磷酰胺")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("1.2万元")]),
            _c("td", [_vm._v("4.8万元")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 导管原位癌 ")]),
            _c("td", [_vm._v("全乳放疗±瘤床加量")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("浸润癌，腋窝淋巴结阴性")]),
            _c("td", [
              _c("p", [
                _vm._v("1、全乳放疗（常规或大分割（首选））±瘤床加量；")
              ]),
              _c("p", [_vm._v("2、部分乳腺短程照射（APBI）")])
            ])
          ]),
          _c("tr", [
            _c("td", [_vm._v("浸润癌，腋窝淋巴结阳性，已清扫")]),
            _c("td", [_vm._v("全乳放疗+瘤床加量+区域淋巴结放疗")])
          ]),
          _c("tr", [
            _c("td", [
              _vm._v("浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫")
            ]),
            _c("td", [_vm._v("全乳放疗（乳房高位切线野）+瘤床加量")])
          ]),
          _c("tr", [
            _c("td", [
              _vm._v("浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫")
            ]),
            _c("td")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("低复发风险患者（全满足）")]),
              _c("p", [_vm._v("1、淋巴结阴性")]),
              _c("p", [_vm._v("2、G1")]),
              _c("p", [_vm._v("3、T≤2cm")]),
              _c("p", [_vm._v("4、Ki-67低表达（≤30%）")])
            ]),
            _c("td", [_vm._v("TAM5年")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("满足以下任意条件者")]),
              _c("p", [_vm._v("1、G2或G3")]),
              _c("p", [_vm._v("2、淋巴结阳性")]),
              _c("p", [_vm._v("3、T>2cm")])
            ]),
            _c("td", [_vm._v(" OFS+TAM5年 ")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("淋巴结阳性>=4个患者")]),
            _c("td", [_vm._v("OFS+AI5年")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成TAM5年初始治疗且耐受良好患者 ")]),
            _c("td", [
              _c("p", [_vm._v("1、未绝经患者：延长TAM治疗至10年")]),
              _c("p", [_vm._v("2、绝经患者：序贯AI治疗5年")])
            ])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成OFS+TAM5年初始治疗且耐受良好患者 ")]),
            _c("td", [_vm._v(" 绝经患者：序贯AI治疗 ")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("完成OFS+AI5年初始治疗且耐受良好患者")]),
            _c("td", [_vm._v("绝经患者：AI治疗")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 全部 ")]),
            _c("td", [
              _c("p", [_vm._v("1、芳香化酶抑制剂（AI）5年")]),
              _c("p", [
                _vm._v(
                  "2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年"
                )
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成AI5年初始治疗且耐受良好低危患者 ")]),
            _c("td", [_vm._v(" 可停药 ")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [
                _vm._v("完成AI5年初始治疗且耐受良好患者，存在以下情况：")
              ]),
              _c("p", [_vm._v("（1）淋巴结阳性")]),
              _c("p", [_vm._v("（2）G3")]),
              _c("p", [
                _vm._v("（3）其他需要行辅助化疗的危险因素，如KI-67＞30%")
              ])
            ]),
            _c("td", [_vm._v(" 可停药 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img2" }, [
      _c("img", { attrs: { src: require(".././../assets/images/tg2.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", { attrs: { rowspan: "3" } }, [_vm._v("AC-T")]),
            _c("td", [_vm._v("多柔比星（多美素）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11200")]),
            _c("td", [_vm._v("44800")]),
            _c("td", [_vm._v("否，全部自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("环磷酰胺（安道生）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("440")]),
            _c("td", [_vm._v("1760")]),
            _c("td", [_vm._v("是，医保甲类")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("序惯紫杉醇（克艾力）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11900")]),
            _c("td", [_vm._v("47600")]),
            _c("td", [_vm._v("是，医保乙类")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("ddAC-ddT")]),
            _c("td", [_vm._v("密集型蒽环类+环磷酰胺序贯密集型紫杉醇")]),
            _c("td", [_vm._v("14")]),
            _c("td", [_vm._v("2.4万元")]),
            _c("td", [_vm._v("9.6万元")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("AC")]),
            _c("td", [_vm._v("蒽环类+环磷酰胺")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("1.2万元")]),
            _c("td", [_vm._v("4.8万元")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("TC")]),
            _c("td", [_vm._v("多西他赛+环磷酰胺")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("1.2万元")]),
            _c("td", [_vm._v("4.8万元")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("腋窝淋巴结清扫术后")]),
              _c("p", [_vm._v("1、T3—T4")]),
              _c("p", [_vm._v("2、腋窝淋巴结阳性")])
            ]),
            _c("td", [_vm._v("胸壁+区域淋巴结放疗")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("前哨淋巴结阳性，未行腋窝淋巴结清扫")]),
            _c("td", [_vm._v(" 胸壁+包括腋窝在内的区域淋巴结放疗 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("低复发风险患者（全满足）")]),
              _c("p", [_vm._v("1、淋巴结阴性")]),
              _c("p", [_vm._v("2、G1")]),
              _c("p", [_vm._v("3、T≤2cm")]),
              _c("p", [_vm._v("4、Ki-67低表达（≤30%）")])
            ]),
            _c("td", [_vm._v("TAM5年")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("满足以下任意条件者")]),
              _c("p", [_vm._v("1、G2或G3")]),
              _c("p", [_vm._v("2、淋巴结阳性")]),
              _c("p", [_vm._v("3、T>2cm")])
            ]),
            _c("td", [_vm._v(" OFS+TAM5年 ")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("淋巴结阳性>=4个患者")]),
            _c("td", [_vm._v("OFS+AI5年")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成TAM5年初始治疗且耐受良好患者 ")]),
            _c("td", [
              _c("p", [_vm._v("1、未绝经患者：延长TAM治疗至10年")]),
              _c("p", [_vm._v("2、绝经患者：序贯AI治疗5年")])
            ])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成OFS+TAM5年初始治疗且耐受良好患者 ")]),
            _c("td", [_vm._v(" 绝经患者：序贯AI治疗 ")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("完成OFS+AI5年初始治疗且耐受良好患者")]),
            _c("td", [_vm._v("绝经患者：AI治疗")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 全部 ")]),
            _c("td", [
              _c("p", [_vm._v("1、AI5年")]),
              _c("p", [_vm._v("2、初始使用TAM治疗，可换用AI5年")])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成AI5年初始治疗且耐受良好低危患者 ")]),
            _c("td", [_vm._v(" 可停药 ")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [
                _vm._v("完成AI5年初始治疗且耐受良好患者，存在以下情况：")
              ]),
              _c("p", [_vm._v("（1）淋巴结阳性")]),
              _c("p", [_vm._v("（2）G3")]),
              _c("p", [
                _vm._v("（3）其他需要行辅助化疗的危险因素，如KI-67＞30%")
              ])
            ]),
            _c("td", [_vm._v(" 继续AI治疗 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img2" }, [
      _c("img", { attrs: { src: require(".././../assets/images/tg2.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(
        " HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。"
      ),
      _c("br"),
      _vm._v(" 针对全切术后，Her2阳性，激素受体阴性的患者"),
      _c("br"),
      _vm._v(" 未行辅助治疗患者的可能用药及费用"),
      _c("br"),
      _vm._v(" 如腋窝淋巴结阳性： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", { attrs: { rowspan: "5" } }, [_vm._v("AC-THP")]),
            _c("td", [_vm._v("多柔比星（多美素）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11200")]),
            _c("td", [_vm._v("44800")]),
            _c("td", [_vm._v("否，全部自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("环磷酰胺（安道生）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("440")]),
            _c("td", [_vm._v("1760")]),
            _c("td", [_vm._v("是，医保甲类")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("序惯紫杉醇（克艾力）")]),
            _c("td", [_vm._v("7")]),
            _c("td", [_vm._v("11900")]),
            _c("td", [_vm._v("142800")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("曲妥珠单抗")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("7600")]),
            _c("td", [_vm._v("30400")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("帕妥珠单抗")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("4955")]),
            _c("td", [_vm._v("24775")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("患者情况")]),
      _c("th", [_vm._v("方案推荐")]),
      _c("th", [_vm._v("预估每疗程花费")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { rowspan: "2" } }, [
      _c("p", [_vm._v("腋窝淋巴结阳性，伴以下危险因素之一：")]),
      _c("p", [_vm._v("1、T>2cm")]),
      _c("p", [_vm._v("2、其他危险因素，如ER阴性")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("腋窝淋巴结清扫术后")]),
              _c("p", [_vm._v("1、T3—T4")]),
              _c("p", [_vm._v("2、腋窝淋巴结阳性")])
            ]),
            _c("td", [_vm._v("胸壁+区域淋巴结放疗")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("前哨淋巴结阳性，未行腋窝淋巴结清扫")]),
            _c("td", [_vm._v(" 胸壁+包括腋窝在内的区域淋巴结放疗 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(" 使用过新辅助化疗患者的可能用药及费用"),
      _c("br"),
      _vm._v(" 如病理学未完全缓解的患者可选用： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("T-DM1")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("45000")]),
            _c("td", [_vm._v("65万")]),
            _c("td", [_vm._v("否，全部自费")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img2" }, [
      _c("img", { attrs: { src: require(".././../assets/images/tg2.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(
        " HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。"
      ),
      _c("br"),
      _vm._v(" 针对保乳术后，Her2阳性，激素受体阴性的患者"),
      _c("br"),
      _vm._v(" 未行辅助治疗患者的可能用药及费用"),
      _c("br"),
      _vm._v(" 如腋窝淋巴结阳性： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", { attrs: { rowspan: "5" } }, [_vm._v("AC-THP")]),
            _c("td", [_vm._v("多柔比星（多美素）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11200")]),
            _c("td", [_vm._v("44800")]),
            _c("td", [_vm._v("否，全部自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("环磷酰胺（安道生）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("440")]),
            _c("td", [_vm._v("1760")]),
            _c("td", [_vm._v("是，医保甲类")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("序惯紫杉醇（克艾力）")]),
            _c("td", [_vm._v("7")]),
            _c("td", [_vm._v("11900")]),
            _c("td", [_vm._v("142800")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("曲妥珠单抗")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("7600")]),
            _c("td", [_vm._v("30400")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("帕妥珠单抗")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("4955")]),
            _c("td", [_vm._v("24775")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("患者情况")]),
      _c("th", [_vm._v("方案推荐")]),
      _c("th", [_vm._v("预估每疗程花费")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { rowspan: "2" } }, [
      _c("p", [_vm._v("腋窝淋巴结阳性，伴以下危险因素之一：")]),
      _c("p", [_vm._v("1、T>2cm")]),
      _c("p", [_vm._v("2、其他危险因素，如ER阴性")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 导管原位癌 ")]),
            _c("td", [_vm._v("全乳放疗±瘤床加量")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("浸润癌，腋窝淋巴结阴性")]),
            _c("td", [
              _c("p", [
                _vm._v("1、全乳放疗（常规或大分割（首选））±瘤床加量；")
              ]),
              _c("p", [_vm._v("2、部分乳腺短程照射（APBI）")])
            ])
          ]),
          _c("tr", [
            _c("td", [_vm._v("浸润癌，腋窝淋巴结阳性，已清扫")]),
            _c("td", [_vm._v(" 全乳放疗+瘤床加量+区域淋巴结放疗 ")])
          ]),
          _c("tr", [
            _c("td", [
              _vm._v("浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫")
            ]),
            _c("td", [_vm._v(" 全乳放疗（乳房高位切线野）+瘤床加量 ")])
          ]),
          _c("tr", [
            _c("td", [
              _vm._v("浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫")
            ]),
            _c("td")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(" 使用过新辅助化疗患者的可能用药及费用"),
      _c("br"),
      _vm._v(" 如病理学未完全缓解的患者可选用： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("T-DM1")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("45000")]),
            _c("td", [_vm._v("65万")]),
            _c("td", [_vm._v("否，全部自费")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img2" }, [
      _c("img", { attrs: { src: require(".././../assets/images/tg2.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(
        " HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。"
      ),
      _c("br"),
      _vm._v(" 针对保乳术后，Her2阳性，激素受体阳性的患者"),
      _c("br"),
      _vm._v(" 未行辅助治疗患者的可能用药及费用"),
      _c("br"),
      _vm._v(" 如腋窝淋巴结阳性： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", { attrs: { rowspan: "5" } }, [_vm._v("AC-THP")]),
            _c("td", [_vm._v("多柔比星（多美素）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11200")]),
            _c("td", [_vm._v("44800")]),
            _c("td", [_vm._v("否，全部自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("环磷酰胺（安道生）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("440")]),
            _c("td", [_vm._v("1760")]),
            _c("td", [_vm._v("是，医保甲类")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("序惯紫杉醇（克艾力）")]),
            _c("td", [_vm._v("7")]),
            _c("td", [_vm._v("11900")]),
            _c("td", [_vm._v("142800")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("曲妥珠单抗")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("7600")]),
            _c("td", [_vm._v("30400")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("帕妥珠单抗")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("4955")]),
            _c("td", [_vm._v("24775")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("患者情况")]),
      _c("th", [_vm._v("方案推荐")]),
      _c("th", [_vm._v("预估每疗程花费")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { rowspan: "2" } }, [
      _c("p", [_vm._v("腋窝淋巴结阳性，伴以下危险因素之一：")]),
      _c("p", [_vm._v("1、T>2cm")]),
      _c("p", [_vm._v("2、其他危险因素，如ER阴性")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 导管原位癌 ")]),
            _c("td", [_vm._v("全乳放疗±瘤床加量")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("浸润癌，腋窝淋巴结阴性")]),
            _c("td", [
              _c("p", [
                _vm._v("1、全乳放疗（常规或大分割（首选））±瘤床加量；")
              ]),
              _c("p", [_vm._v("2、部分乳腺短程照射（APBI）")])
            ])
          ]),
          _c("tr", [
            _c("td", [_vm._v("浸润癌，腋窝淋巴结阳性，已清扫")]),
            _c("td", [_vm._v(" 全乳放疗+瘤床加量+区域淋巴结放疗 ")])
          ]),
          _c("tr", [
            _c("td", [
              _vm._v("浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫")
            ]),
            _c("td", [_vm._v(" 全乳放疗（乳房高位切线野）+瘤床加量 ")])
          ]),
          _c("tr", [
            _c("td", [
              _vm._v("浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫")
            ]),
            _c("td")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(" 绝经前"),
      _c("br"),
      _vm._v(" 初始治疗： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("低复发风险患者（全满足）")]),
              _c("p", [_vm._v("1、淋巴结阴性")]),
              _c("p", [_vm._v("2、G1")]),
              _c("p", [_vm._v("3、T≤2cm")]),
              _c("p", [_vm._v("4、Ki-67低表达（≤30%）")])
            ]),
            _c("td", [_vm._v(" TAM5年 ")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("满足以下任意条件者")]),
              _c("p", [_vm._v("1、G2或G3")]),
              _c("p", [_vm._v("2、淋巴结阳性")]),
              _c("p", [_vm._v("3、T>2cm")])
            ]),
            _c("td", [_vm._v(" OFS+TAM5年 ")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 淋巴结阳性>=4个患者 ")]),
            _c("td", [_vm._v(" OFS+AI5年 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成TAM5年初始治疗且耐受良好患者 ")]),
            _c("td", [
              _c("p", [_vm._v("1、未绝经患者：延长TAM治疗至10年")]),
              _c("p", [_vm._v("2、绝经患者：序贯AI治疗5年")])
            ])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成OFS+TAM5年初始治疗且耐受良好患者 ")]),
            _c("td", [_vm._v(" 绝经患者：序贯AI治疗 ")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成OFS+AI5年初始治疗且耐受良好患者 ")]),
            _c("td", [_vm._v(" 绝经患者：AI治疗 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(" 绝经后"),
      _c("br"),
      _vm._v(" 初始治疗： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 全部 ")]),
            _c("td", [
              _c("p", [_vm._v("1、芳香化酶抑制剂（AI）5年")]),
              _c("p", [
                _vm._v(
                  "2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年"
                )
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成AI5年初始治疗且耐受良好低危患者 ")]),
            _c("td", [_vm._v(" 可停药 ")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [
                _vm._v("完成AI5年初始治疗且耐受良好患者，存在以下情况：")
              ]),
              _c("p", [_vm._v("（1）淋巴结阳性")]),
              _c("p", [_vm._v("（2）G3")]),
              _c("p", [
                _vm._v("（3）其他需要行辅助化疗的危险因素，如KI-67＞30%")
              ])
            ]),
            _c("td", [_vm._v(" 继续AI治疗 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(" 使用过新辅助化疗患者的可能用药及费用"),
      _c("br"),
      _vm._v(" 如病理学未完全缓解的患者可选用： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("T-DM1")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("45000")]),
            _c("td", [_vm._v("65万")]),
            _c("td", [_vm._v("否，全部自费")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img2" }, [
      _c("img", { attrs: { src: require(".././../assets/images/tg2.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(
        " HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。"
      ),
      _c("br"),
      _vm._v(" 针对全切术后，Her2阳性，激素受体阳性的患者"),
      _c("br"),
      _vm._v(" 未行辅助治疗患者的可能用药及费用"),
      _c("br"),
      _vm._v(" 如腋窝淋巴结阳性： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("方案名称")]),
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", { attrs: { rowspan: "5" } }, [_vm._v("AC-THP")]),
            _c("td", [_vm._v("多柔比星（多美素）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("11200")]),
            _c("td", [_vm._v("44800")]),
            _c("td", [_vm._v("否，全部自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("环磷酰胺（安道生）")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("440")]),
            _c("td", [_vm._v("1760")]),
            _c("td", [_vm._v("是，医保甲类")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("序惯紫杉醇（克艾力）")]),
            _c("td", [_vm._v("7")]),
            _c("td", [_vm._v("11900")]),
            _c("td", [_vm._v("142800")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("曲妥珠单抗")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("7600")]),
            _c("td", [_vm._v("30400")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("帕妥珠单抗")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("4955")]),
            _c("td", [_vm._v("24775")]),
            _c("td", [_vm._v("是，医保乙类，部分自费")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("患者情况")]),
      _c("th", [_vm._v("方案推荐")]),
      _c("th", [_vm._v("预估每疗程花费")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { rowspan: "2" } }, [
      _c("p", [_vm._v("腋窝淋巴结阳性，伴以下危险因素之一：")]),
      _c("p", [_vm._v("1、T>2cm")]),
      _c("p", [_vm._v("2、其他危险因素，如ER阴性")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("腋窝淋巴结清扫术后")]),
              _c("p", [_vm._v("1、T3—T4")]),
              _c("p", [_vm._v("2、腋窝淋巴结阳性")])
            ]),
            _c("td", [_vm._v("胸壁+区域淋巴结放疗")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("前哨淋巴结阳性，未行腋窝淋巴结清扫")]),
            _c("td", [_vm._v(" 胸壁+包括腋窝在内的区域淋巴结放疗 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(" 绝经前"),
      _c("br"),
      _vm._v(" 初始治疗： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("低复发风险患者（全满足）")]),
              _c("p", [_vm._v("1、淋巴结阴性")]),
              _c("p", [_vm._v("2、G1")]),
              _c("p", [_vm._v("3、T≤2cm")]),
              _c("p", [_vm._v("4、Ki-67低表达（≤30%）")])
            ]),
            _c("td", [_vm._v(" TAM5年 ")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [_vm._v("满足以下任意条件者")]),
              _c("p", [_vm._v("1、G2或G3")]),
              _c("p", [_vm._v("2、淋巴结阳性")]),
              _c("p", [_vm._v("3、T>2cm")])
            ]),
            _c("td", [_vm._v(" OFS+TAM5年 ")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 淋巴结阳性>=4个患者 ")]),
            _c("td", [_vm._v(" OFS+AI5年 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成TAM5年初始治疗且耐受良好患者 ")]),
            _c("td", [
              _c("p", [_vm._v("1、未绝经患者：延长TAM治疗至10年")]),
              _c("p", [_vm._v("2、绝经患者：序贯AI治疗5年")])
            ])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成OFS+TAM5年初始治疗且耐受良好患者 ")]),
            _c("td", [_vm._v(" 绝经患者：序贯AI治疗 ")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成OFS+AI5年初始治疗且耐受良好患者 ")]),
            _c("td", [_vm._v(" 绝经患者：AI治疗 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(" 绝经后"),
      _c("br"),
      _vm._v(" 初始治疗： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 全部 ")]),
            _c("td", [
              _c("p", [_vm._v("1、芳香化酶抑制剂（AI）5年")]),
              _c("p", [
                _vm._v(
                  "2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年"
                )
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table" }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("患者情况")]),
            _c("th", [_vm._v("一级推荐")])
          ]),
          _c("tr", [
            _c("td", [_vm._v(" 完成AI5年初始治疗且耐受良好低危患者 ")]),
            _c("td", [_vm._v(" 可停药 ")])
          ]),
          _c("tr", [
            _c("td", [
              _c("p", [
                _vm._v("完成AI5年初始治疗且耐受良好患者，存在以下情况：")
              ]),
              _c("p", [_vm._v("（1）淋巴结阳性")]),
              _c("p", [_vm._v("（2）G3")]),
              _c("p", [
                _vm._v("（3）其他需要行辅助化疗的危险因素，如KI-67＞30%")
              ])
            ]),
            _c("td", [_vm._v(" 继续AI治疗 ")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jieshao" }, [
      _vm._v(" 使用过新辅助化疗患者的可能用药及费用"),
      _c("br"),
      _vm._v(" 如病理学未完全缓解的患者可选用： ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-box" }, [
      _c("div", { staticClass: "table", staticStyle: { width: "580px" } }, [
        _c("table", [
          _c("tr", [
            _c("th", [_vm._v("药品")]),
            _c("th", [_vm._v("天/疗程")]),
            _c("th", [_vm._v("预估每疗程费用")]),
            _c("th", [_vm._v("预估4个疗程（84天）费用")]),
            _c("th", [_vm._v("是否纳入医保，需自费")])
          ]),
          _c("tr", [
            _c("td", [_vm._v("T-DM1")]),
            _c("td", [_vm._v("21")]),
            _c("td", [_vm._v("45000")]),
            _c("td", [_vm._v("65万")]),
            _c("td", [_vm._v("否，全部自费")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img2" }, [
      _c("img", { attrs: { src: require(".././../assets/images/tg2.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "about" }, [
      _c("div", { staticClass: "tips" }, [_vm._v("关于乳腺癌专病保险：")]),
      _c("div", { staticClass: "center" }, [
        _vm._v(" 乳腺癌患者如果想购买一般的重疾险和医疗险，都会被拒保。"),
        _c("br"),
        _vm._v(
          " 若符合投保条件成功购买了这款专为乳腺癌患者设计的保险，如果万一未来疾病进展就医且符合理赔条件的情况下，则可获得相应保额的赔付，为您减轻高昂治疗费的负担。 "
        )
      ]),
      _c("div", { staticClass: "img3" }, [
        _c("img", { attrs: { src: require(".././../assets/images/tg3.png") } })
      ]),
      _c("div")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }