<template>
  <div class="knowledge">
<!--    <div class="top">-->
<!--      <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>-->
<!--      <div class="wenti-box">-->
<!--        <div>选择选项</div>-->
<!--        <div>-->
<!--          <van-form>-->
<!--            <van-field name="radio" label="术式:">-->
<!--              <template #input>-->
<!--                <van-radio-group v-model="operation" @change="reset" direction="horizontal">-->
<!--                  <van-radio name="A">A.保乳</van-radio>-->
<!--                  <van-radio name="B">B.全切</van-radio>-->
<!--                </van-radio-group>-->
<!--              </template>-->
<!--            </van-field>-->
<!--            <van-field name="radio" label="Her2:">-->
<!--              <template #input>-->
<!--                <van-radio-group v-model="her2" @change="reset" direction="horizontal">-->
<!--                  <van-radio name="A">A.阴性</van-radio>-->
<!--                  <van-radio name="B">B.阳性</van-radio>-->
<!--                </van-radio-group>-->
<!--              </template>-->
<!--            </van-field>-->
<!--            <van-field name="radio" label="激素受体:">-->
<!--              <template #input>-->
<!--                <van-radio-group v-model="hormone" @change="reset" direction="horizontal">-->
<!--                  <van-radio name="A">A.阴性</van-radio>-->
<!--                  <van-radio name="B">B.阳性</van-radio>-->
<!--                </van-radio-group>-->
<!--              </template>-->
<!--            </van-field>-->
<!--            <div style="margin: 16px; display: flex">-->
<!--              <van-button round plain block type="info" @click="onReset" style="margin-right: .2rem">清空</van-button>-->
<!--              <van-button round block type="info" native-type="submit" @click="onSubmit"style="margin-left: .2rem">提交</van-button>-->
<!--            </div>-->
<!--          </van-form>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="bottom" v-if="answer!=''">
      <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>
      <div class="knowledge-box">
        <div class="title">预估主要治疗药物和费用</div>
        <div class="tijian" v-if="answer=='AAA'">针对保乳术后、Her2阴性、激素受体阴性的患者</div>
        <div class="tijian" v-if="answer=='BAA'">针对全切术后，Her2阴性，激素受体阴性的患者</div>
        <div class="tijian" v-if="answer=='AAB'">针对保乳术后，Her2阴性，激素受体阳性的患者</div>
        <div class="tijian" v-if="answer=='BAB'">针对全切术后，Her2阴性，激素受体阳性的患者</div>
        <div class="tijian" v-if="answer=='BBA'">针对全切术后，Her2阳性，激素受体阴性的患者</div>
        <div class="tijian" v-if="answer=='ABA'">针对保乳术后，Her2阳性，激素受体阴性的患者</div>
        <div class="tijian" v-if="answer=='ABB'">针对保乳术后，Her2阳性，激素受体阳性的患者</div>
        <div class="tijian" v-if="answer=='BBB'">针对全切术后，Her2阳性，激素受体阳性的患者</div>
        <div class="mianze">
          <div class="tips">免责条款：</div>
          <div style="line-height: .4rem">
            如下治疗方案及药物等信息来源于国家临床指南，但仅作为预估治疗费用的依据，不得作为患者诊疗方案的建议。本平台不承担诊疗行为相关的任何责任。
          </div>
        </div>
        <div class="yuhou">
          <div class="tips">关于预后：</div>
          <div class="center">
            如您有足够资金完成系统性治疗，那么您带病生存周期就更长，带病生存质量也更好。
          </div>
          <div class="list-box">
            <div class="tips">根据疾病分期，且您有足够资金完成系统性治疗，则生存期为：</div>
            <div class="list">
                <ul>
                  <li><span>0 - 1</span>期患者：五年生存率99％</li>
                  <li><span>2 - 3</span>期患者：五年生存率是86％</li>
                  <li><span>4期</span>五年相对生存率是27％</li>
                </ul>
            </div>
          </div>
          <div class="img1"><img src=".././../assets/images/tg1.png"> </div>
        </div>
      </div>
      <div class="zhuliao" v-if="answer=='AAA'">
        <div class="tips">预估疾病进展及未来主要治疗药物和费用：</div>
        <div class="center">
          一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。
        </div>
        <div class="jieshao">
          针对保乳术后，Her2阴性，激素受体阴性的患者可能用药及费用如下：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>方案名称</th>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td rowspan="3">AC-T</td>
                <td>多柔比星（多美素）</td>
                <td>21</td>
                <td>11200</td>
                <td>44800</td>
                <td>否，全部自费</td>
              </tr>
              <tr>
                <td>环磷酰胺（安道生）</td>
                <td>21</td>
                <td>440</td>
                <td>1760</td>
                <td>是，医保甲类</td>
              </tr>
              <tr>
                <td>序惯紫杉醇（克艾力）</td>
                <td>21</td>
                <td>11900</td>
                <td>47600</td>
                <td>是，医保乙类</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="more" @click="show =!show">可能的其他治疗方案详情>></div>
        <div v-if="show">
          <div class="jieshao">化疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>方案名称</th>
                  <th>药品</th>
                  <th>天/疗程</th>
                  <th>预估每疗程费用</th>
                  <th>预估4个疗程（84天）费用</th>
                </tr>
                <tr>
                  <td>ddAC-ddT</td>
                  <td>密集型蒽环类+环磷酰胺序贯密集型紫杉醇</td>
                  <td>14</td>
                  <td>2.4万元</td>
                  <td>9.6万元</td>
                </tr>
                <tr>
                  <td>AC</td>
                  <td>蒽环类+环磷酰胺</td>
                  <td>21</td>
                  <td>1.2万元</td>
                  <td>4.8万元</td>
                </tr>
                <tr>
                  <td>TC</td>
                  <td>多西他赛+环磷酰胺</td>
                  <td>21</td>
                  <td>1.2万元</td>
                  <td>4.8万元</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
          <div class="jieshao">放疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>导管原位癌</td>
                  <td>全乳放疗±瘤床加量</td>
                </tr>
                <tr>
                  <td>浸润癌，腋窝淋巴结阴性</td>
                  <td>1、全乳放疗（常规或大分割（首选））±瘤床加量；
                    2、部分乳腺短程照射（APBI）</td>
                </tr>
                <tr>
                  <td>浸润癌，腋窝淋巴结阳性，已清扫</td>
                  <td>全乳放疗+瘤床加量+区域淋巴结放疗</td>
                </tr>
                <tr>
                  <td>浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫</td>
                  <td>全乳放疗（乳房高位切线野）+瘤床加量</td>
                </tr>
                <tr>
                  <td>浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫</td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
        </div>
        <div class="img2"><img src=".././../assets/images/tg2.png"> </div>
      </div>
      <div class="zhuliao" v-if="answer=='BAA'">
        <div class="tips">预估疾病进展及未来主要治疗药物和费用：</div>
        <div class="center">
          一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。
        </div>
        <div class="jieshao">
          针对全切术后，Her2阴性，激素受体阴性的患者可能用药及费用如下：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>方案名称</th>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td rowspan="3">AC-T</td>
                <td>多柔比星（多美素）</td>
                <td>21</td>
                <td>11200</td>
                <td>44800</td>
                <td>否，全部自费</td>
              </tr>
              <tr>
                <td>环磷酰胺（安道生）</td>
                <td>21</td>
                <td>440</td>
                <td>1760</td>
                <td>是，医保甲类</td>
              </tr>
              <tr>
                <td>序惯紫杉醇（克艾力）</td>
                <td>21</td>
                <td>11900</td>
                <td>47600</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="more" @click="show =!show">可能的其他治疗方案详情>></div>
        <div v-if="show">
          <div class="jieshao">化疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>方案名称</th>
                  <th>药品</th>
                  <th>天/疗程</th>
                  <th>预估每疗程费用</th>
                  <th>预估4个疗程（84天）费用</th>
                </tr>
                <tr>
                  <td>ddAC-ddT</td>
                  <td>密集型蒽环类+环磷酰胺序贯密集型紫杉醇</td>
                  <td>14</td>
                  <td>2.4万元</td>
                  <td>9.6万元</td>
                </tr>
                <tr>
                  <td>AC</td>
                  <td>蒽环类+环磷酰胺</td>
                  <td>21</td>
                  <td>1.2万元</td>
                  <td>4.8万元</td>
                </tr>
                <tr>
                  <td>TC</td>
                  <td>多西他赛+环磷酰胺</td>
                  <td>21</td>
                  <td>1.2万元</td>
                  <td>4.8万元</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
          <div class="jieshao">放疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    <p>腋窝淋巴结清扫术后</p>
                    <p>1、T3—T4</p>
                    <p>2、腋窝淋巴结阳性</p>
                  </td>
                  <td>胸壁+区域淋巴结放疗</td>
                </tr>
                <tr>
                  <td>前哨淋巴结阳性，未行腋窝淋巴结清扫</td>
                  <td>胸壁+包括腋窝在内的区域淋巴结放疗</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
        </div>
        <div class="img2"><img src=".././../assets/images/tg2.png"> </div>
      </div>
      <div class="zhuliao" v-if="answer=='AAB'">
        <div class="tips">预估疾病进展及未来主要治疗药物和费用：</div>
        <div class="center">
          一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。
        </div>
        <div class="jieshao">
          针对保乳术后，Her2阴性，激素受体阳性的患者可能用药及费用如下：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>方案名称</th>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td rowspan="3">AC-T</td>
                <td>多柔比星（多美素）</td>
                <td>21</td>
                <td>11200</td>
                <td>44800</td>
                <td>否，全部自费</td>
              </tr>
              <tr>
                <td>环磷酰胺（安道生）</td>
                <td>21</td>
                <td>440</td>
                <td>1760</td>
                <td>是，医保甲类</td>
              </tr>
              <tr>
                <td>序惯紫杉醇（克艾力）</td>
                <td>21</td>
                <td>11900</td>
                <td>47600</td>
                <td>是，医保乙类</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="more" @click="show =!show">可能的其他治疗方案详情>></div>
        <div v-if="show">
          <div class="jieshao">化疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>方案名称</th>
                  <th>药品</th>
                  <th>天/疗程</th>
                  <th>预估每疗程费用</th>
                  <th>预估4个疗程（84天）费用</th>
                </tr>
                <tr>
                  <td>ddAC-ddT</td>
                  <td>密集型蒽环类+环磷酰胺序贯密集型紫杉醇</td>
                  <td>14</td>
                  <td>2.4万元</td>
                  <td>9.6万元</td>
                </tr>
                <tr>
                  <td>AC</td>
                  <td>蒽环类+环磷酰胺</td>
                  <td>21</td>
                  <td>1.2万元</td>
                  <td>4.8万元</td>
                </tr>
                <tr>
                  <td>TC</td>
                  <td>多西他赛+环磷酰胺</td>
                  <td>21</td>
                  <td>1.2万元</td>
                  <td>4.8万元</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
          <div class="jieshao">放疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    导管原位癌
                  </td>
                  <td>全乳放疗±瘤床加量</td>
                </tr>
                <tr>
                  <td>浸润癌，腋窝淋巴结阴性</td>
                  <td>
                    <p>1、全乳放疗（常规或大分割（首选））±瘤床加量；</p>
                    <p>2、部分乳腺短程照射（APBI）</p>
                  </td>
                </tr>
                <tr>
                  <td>浸润癌，腋窝淋巴结阳性，已清扫</td>
                  <td>全乳放疗+瘤床加量+区域淋巴结放疗</td>
                </tr>
                <tr>
                  <td>浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫</td>
                  <td>全乳放疗（乳房高位切线野）+瘤床加量</td>
                </tr>
                <tr>
                  <td>浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫</td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
        </div>
        <div class="more" @click="show1 =!show1">放化疗完成后，其他后续内分泌方案见详情>></div>
        <div v-if="show1">
          <div class="jieshao">绝经前</div>
          <div class="jieshao">初始治疗：</div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    <p>低复发风险患者（全满足）</p>
                    <p>1、淋巴结阴性</p>
                    <p>2、G1</p>
                    <p>3、T≤2cm</p>
                    <p>4、Ki-67低表达（≤30%）</p>
                  </td>
                  <td>TAM5年</td>
                </tr>
                <tr>
                  <td>
                    <p>满足以下任意条件者</p>
                    <p>1、G2或G3</p>
                    <p>2、淋巴结阳性</p>
                    <p>3、T>2cm</p>
                  </td>
                  <td>
                    OFS+TAM5年
                  </td>
                </tr>
                <tr>
                  <td>淋巴结阳性>=4个患者</td>
                  <td>OFS+AI5年</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="jieshao">延长治疗：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    完成TAM5年初始治疗且耐受良好患者
                  </td>
                  <td>
                    <p>1、未绝经患者：延长TAM治疗至10年</p>
                    <p>2、绝经患者：序贯AI治疗5年</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    完成OFS+TAM5年初始治疗且耐受良好患者
                  </td>
                  <td>
                    绝经患者：序贯AI治疗
                  </td>
                </tr>
                <tr>
                  <td>完成OFS+AI5年初始治疗且耐受良好患者</td>
                  <td>绝经患者：AI治疗</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
          <div class="jieshao">绝经后</div>
          <div class="jieshao">初始治疗：</div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    全部
                  </td>
                  <td>
                    <p>1、芳香化酶抑制剂（AI）5年</p>
                    <p>2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="jieshao">延长治疗：</div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    完成AI5年初始治疗且耐受良好低危患者
                  </td>
                  <td>
                    可停药
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>完成AI5年初始治疗且耐受良好患者，存在以下情况：</p>
                    <p>（1）淋巴结阳性</p>
                    <p>（2）G3</p>
                    <p>（3）其他需要行辅助化疗的危险因素，如KI-67＞30%</p>
                  </td>
                  <td>
                    可停药
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="img2"><img src=".././../assets/images/tg2.png"> </div>
      </div>
      <div class="zhuliao" v-if="answer=='BAB'">
        <div class="tips">预估疾病进展及未来主要治疗药物和费用：</div>
        <div class="center">
          一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。
        </div>
        <div class="jieshao">
          针对全切术后，Her2阴性，激素受体阳性的患者可能用药及费用如下：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>方案名称</th>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td rowspan="3">AC-T</td>
                <td>多柔比星（多美素）</td>
                <td>21</td>
                <td>11200</td>
                <td>44800</td>
                <td>否，全部自费</td>
              </tr>
              <tr>
                <td>环磷酰胺（安道生）</td>
                <td>21</td>
                <td>440</td>
                <td>1760</td>
                <td>是，医保甲类</td>
              </tr>
              <tr>
                <td>序惯紫杉醇（克艾力）</td>
                <td>21</td>
                <td>11900</td>
                <td>47600</td>
                <td>是，医保乙类</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="more" @click="show =!show">可能的其他治疗方案详情>></div>
        <div v-if="show">
          <div class="jieshao">化疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>方案名称</th>
                  <th>药品</th>
                  <th>天/疗程</th>
                  <th>预估每疗程费用</th>
                  <th>预估4个疗程（84天）费用</th>
                </tr>
                <tr>
                  <td>ddAC-ddT</td>
                  <td>密集型蒽环类+环磷酰胺序贯密集型紫杉醇</td>
                  <td>14</td>
                  <td>2.4万元</td>
                  <td>9.6万元</td>
                </tr>
                <tr>
                  <td>AC</td>
                  <td>蒽环类+环磷酰胺</td>
                  <td>21</td>
                  <td>1.2万元</td>
                  <td>4.8万元</td>
                </tr>
                <tr>
                  <td>TC</td>
                  <td>多西他赛+环磷酰胺</td>
                  <td>21</td>
                  <td>1.2万元</td>
                  <td>4.8万元</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
          <div class="jieshao">放疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    <p>腋窝淋巴结清扫术后</p>
                    <p>1、T3—T4</p>
                    <p>2、腋窝淋巴结阳性</p>
                  </td>
                  <td>胸壁+区域淋巴结放疗</td>
                </tr>
                <tr>
                  <td>前哨淋巴结阳性，未行腋窝淋巴结清扫</td>
                  <td>
                    胸壁+包括腋窝在内的区域淋巴结放疗
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
        </div>
        <div class="more" @click="show1 =!show1">放化疗完成后，其他后续内分泌方案见详情>></div>
        <div v-if="show1">
          <div class="jieshao">绝经前</div>
          <div class="jieshao">初始治疗：</div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    <p>低复发风险患者（全满足）</p>
                    <p>1、淋巴结阴性</p>
                    <p>2、G1</p>
                    <p>3、T≤2cm</p>
                    <p>4、Ki-67低表达（≤30%）</p>
                  </td>
                  <td>TAM5年</td>
                </tr>
                <tr>
                  <td>
                    <p>满足以下任意条件者</p>
                    <p>1、G2或G3</p>
                    <p>2、淋巴结阳性</p>
                    <p>3、T>2cm</p>
                  </td>
                  <td>
                    OFS+TAM5年
                  </td>
                </tr>
                <tr>
                  <td>淋巴结阳性>=4个患者</td>
                  <td>OFS+AI5年</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="jieshao">延长治疗：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    完成TAM5年初始治疗且耐受良好患者
                  </td>
                  <td>
                    <p>1、未绝经患者：延长TAM治疗至10年</p>
                    <p>2、绝经患者：序贯AI治疗5年</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    完成OFS+TAM5年初始治疗且耐受良好患者
                  </td>
                  <td>
                    绝经患者：序贯AI治疗
                  </td>
                </tr>
                <tr>
                  <td>完成OFS+AI5年初始治疗且耐受良好患者</td>
                  <td>绝经患者：AI治疗</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
          <div class="jieshao">绝经后</div>
          <div class="jieshao">初始治疗：</div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    全部
                  </td>
                  <td>
                    <p>1、AI5年</p>
                    <p>2、初始使用TAM治疗，可换用AI5年</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="jieshao">延长治疗：</div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    完成AI5年初始治疗且耐受良好低危患者
                  </td>
                  <td>
                    可停药
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>完成AI5年初始治疗且耐受良好患者，存在以下情况：</p>
                    <p>（1）淋巴结阳性</p>
                    <p>（2）G3</p>
                    <p>（3）其他需要行辅助化疗的危险因素，如KI-67＞30%</p>
                  </td>
                  <td>
                    继续AI治疗
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="img2"><img src=".././../assets/images/tg2.png"> </div>
      </div>
      <div class="zhuliao" v-if="answer=='BBA'">
        <div class="tips">预估疾病进展及未来主要治疗药物和费用：</div>
        <div class="center">
          一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是较大压力。
        </div>
        <div class="jieshao">
          HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。<br>
          针对全切术后，Her2阳性，激素受体阴性的患者<br>
          未行辅助治疗患者的可能用药及费用<br>
          如腋窝淋巴结阳性：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>方案名称</th>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td rowspan="5">AC-THP</td>
                <td>多柔比星（多美素）</td>
                <td>21</td>
                <td>11200</td>
                <td>44800</td>
                <td>否，全部自费</td>
              </tr>
              <tr>
                <td>环磷酰胺（安道生）</td>
                <td>21</td>
                <td>440</td>
                <td>1760</td>
                <td>是，医保甲类</td>
              </tr>
              <tr>
                <td>序惯紫杉醇（克艾力）</td>
                <td>7</td>
                <td>11900</td>
                <td>142800</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
              <tr>
                <td>曲妥珠单抗</td>
                <td>21</td>
                <td>7600</td>
                <td>30400</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
              <tr>
                <td>帕妥珠单抗</td>
                <td>21</td>
                <td>4955</td>
                <td>24775</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="more" @click="show =!show">可能的其他治疗方案详情>></div>
        <div v-if="show">
          <div class="jieshao">化疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>方案推荐</th>
                  <th>预估每疗程花费</th>
                </tr>
                <tr>
                  <td rowspan="2">腋窝淋巴结阳性</td>
                  <td><popover title="AC-THP" :center=" ACTHPTips" /></td>
                  <td>3.5万元</td>
                </tr>
                <tr>
                  <td><popover title="TCbHP" :center=" TCbHPTips" /></td>
                  <td>3.3万元</td>
                </tr>
                <tr>
                  <td rowspan="2">
                    <p>腋窝淋巴结阳性，伴以下危险因素之一：</p>
                    <p>1、T>2cm</p>
                    <p>2、其他危险因素，如ER阴性</p>
                  </td>
                  <td><popover title="AC-TH" :center=" ACTHTips" /></td>
                  <td>2.9万元</td>
                </tr>
                <tr>
                  <td><popover title="TCbH" :center="TCbHTips" /></td>
                  <td>3.0万元</td>
                </tr>
                <tr>
                  <td>腋窝淋巴结阳性且T<2cm</td>
                  <td><popover title="TC+H" :center="TCbTips" /></td>
                  <td>2.7万元</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
          <div class="jieshao">放疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    <p>腋窝淋巴结清扫术后</p>
                    <p>1、T3—T4</p>
                    <p>2、腋窝淋巴结阳性</p>
                  </td>
                  <td>胸壁+区域淋巴结放疗</td>
                </tr>
                <tr>
                  <td>前哨淋巴结阳性，未行腋窝淋巴结清扫</td>
                  <td>
                    胸壁+包括腋窝在内的区域淋巴结放疗
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
        </div>
        <div class="jieshao">
          使用过新辅助化疗患者的可能用药及费用<br>
          如病理学未完全缓解的患者可选用：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td>T-DM1</td>
                <td>21</td>
                <td>45000</td>
                <td>65万</td>
                <td>否，全部自费</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="img2"><img src=".././../assets/images/tg2.png"> </div>
      </div>
      <div class="zhuliao" v-if="answer=='ABA'">
        <div class="tips">预估疾病进展及未来主要治疗药物和费用：</div>
        <div class="center">
          一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是很大的压力。
        </div>
        <div class="jieshao">
          HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。<br>
          针对保乳术后，Her2阳性，激素受体阴性的患者<br>
          未行辅助治疗患者的可能用药及费用<br>
          如腋窝淋巴结阳性：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>方案名称</th>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td rowspan="5">AC-THP</td>
                <td>多柔比星（多美素）</td>
                <td>21</td>
                <td>11200</td>
                <td>44800</td>
                <td>否，全部自费</td>
              </tr>
              <tr>
                <td>环磷酰胺（安道生）</td>
                <td>21</td>
                <td>440</td>
                <td>1760</td>
                <td>是，医保甲类</td>
              </tr>
              <tr>
                <td>序惯紫杉醇（克艾力）</td>
                <td>7</td>
                <td>11900</td>
                <td>142800</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
              <tr>
                <td>曲妥珠单抗</td>
                <td>21</td>
                <td>7600</td>
                <td>30400</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
              <tr>
                <td>帕妥珠单抗</td>
                <td>21</td>
                <td>4955</td>
                <td>24775</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="more" @click="show =!show">可能的其他治疗方案详情>></div>
        <div v-if="show">
          <div class="jieshao">化疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>方案推荐</th>
                  <th>预估每疗程花费</th>
                </tr>
                <tr>
                  <td rowspan="2">腋窝淋巴结阳性</td>
                  <td><popover title="AC-THP" :center=" ACTHPTips" /></td>
                  <td>3.5万元</td>
                </tr>
                <tr>
                  <td><popover title="TCbHP" :center=" TCbHPTips" /></td>
                  <td>3.3万元</td>
                </tr>
                <tr>
                  <td rowspan="2">
                    <p>腋窝淋巴结阳性，伴以下危险因素之一：</p>
                    <p>1、T>2cm</p>
                    <p>2、其他危险因素，如ER阴性</p>
                  </td>
                  <td><popover title="AC-TH" :center=" ACTHTips" /></td>
                  <td>2.9万元</td>
                </tr>
                <tr>
                  <td><popover title="TCbH" :center="TCbHTips" /></td>
                  <td>3.0万元</td>
                </tr>
                <tr>
                  <td>腋窝淋巴结阳性且T<2cm</td>
                  <td><popover title="TC+H" :center="TCbTips" /></td>
                  <td>2.7万元</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
          <div class="jieshao">放疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    导管原位癌
                  </td>
                  <td>全乳放疗±瘤床加量</td>
                </tr>
                <tr>
                  <td>浸润癌，腋窝淋巴结阴性</td>
                  <td>
                    <p>1、全乳放疗（常规或大分割（首选））±瘤床加量；</p>
                    <p>2、部分乳腺短程照射（APBI）</p>
                  </td>
                </tr>
                <tr>
                  <td>浸润癌，腋窝淋巴结阳性，已清扫</td>
                  <td>
                    全乳放疗+瘤床加量+区域淋巴结放疗
                  </td>
                </tr>
                <tr>
                  <td>浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫</td>
                  <td>
                    全乳放疗（乳房高位切线野）+瘤床加量
                  </td>
                </tr>
                <tr>
                  <td>浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫</td>
                  <td>

                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
        </div>
        <div class="jieshao">
          使用过新辅助化疗患者的可能用药及费用<br>
          如病理学未完全缓解的患者可选用：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td>T-DM1</td>
                <td>21</td>
                <td>45000</td>
                <td>65万</td>
                <td>否，全部自费</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="img2"><img src=".././../assets/images/tg2.png"> </div>
      </div>
      <div class="zhuliao" v-if="answer=='ABB'">
        <div class="tips">预估疾病进展及未来主要治疗药物和费用：</div>
        <div class="center">
          一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是很大的压力。
        </div>
        <div class="jieshao">
          HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。<br>
          针对保乳术后，Her2阳性，激素受体阳性的患者<br>
          未行辅助治疗患者的可能用药及费用<br>
          如腋窝淋巴结阳性：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>方案名称</th>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td rowspan="5">AC-THP</td>
                <td>多柔比星（多美素）</td>
                <td>21</td>
                <td>11200</td>
                <td>44800</td>
                <td>否，全部自费</td>
              </tr>
              <tr>
                <td>环磷酰胺（安道生）</td>
                <td>21</td>
                <td>440</td>
                <td>1760</td>
                <td>是，医保甲类</td>
              </tr>
              <tr>
                <td>序惯紫杉醇（克艾力）</td>
                <td>7</td>
                <td>11900</td>
                <td>142800</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
              <tr>
                <td>曲妥珠单抗</td>
                <td>21</td>
                <td>7600</td>
                <td>30400</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
              <tr>
                <td>帕妥珠单抗</td>
                <td>21</td>
                <td>4955</td>
                <td>24775</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="more" @click="show =!show">可能的其他治疗方案详情>></div>
        <div v-if="show">
          <div class="jieshao">化疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>方案推荐</th>
                  <th>预估每疗程花费</th>
                </tr>
                <tr>
                  <td rowspan="2">腋窝淋巴结阳性</td>
                  <td><popover title="AC-THP" :center="ACTHPTips" /></td>
                  <td>3.5万元</td>
                </tr>
                <tr>
                  <td><popover title="TCbHP" :center="TCbHPTips" /></td>
                  <td>3.3万元</td>
                </tr>
                <tr>
                  <td rowspan="2">
                    <p>腋窝淋巴结阳性，伴以下危险因素之一：</p>
                    <p>1、T>2cm</p>
                    <p>2、其他危险因素，如ER阴性</p>
                  </td>
                  <td><popover title="AC-TH" :center="ACTHTips" /></td>
                  <td>2.9万元</td>
                </tr>
                <tr>
                  <td><popover title="TCbH" :center="TCbHTips" /></td>
                  <td>3.0万元</td>
                </tr>
                <tr>
                  <td>腋窝淋巴结阳性且T<2cm</td>
                  <td><popover title="TC+H" :center="TCbTips" /></td>
                  <td>2.7万元</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
          <div class="jieshao">放疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    导管原位癌
                  </td>
                  <td>全乳放疗±瘤床加量</td>
                </tr>
                <tr>
                  <td>浸润癌，腋窝淋巴结阴性</td>
                  <td>
                    <p>1、全乳放疗（常规或大分割（首选））±瘤床加量；</p>
                    <p>2、部分乳腺短程照射（APBI）</p>
                  </td>
                </tr>
                <tr>
                  <td>浸润癌，腋窝淋巴结阳性，已清扫</td>
                  <td>
                    全乳放疗+瘤床加量+区域淋巴结放疗
                  </td>
                </tr>
                <tr>
                  <td>浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫</td>
                  <td>
                    全乳放疗（乳房高位切线野）+瘤床加量
                  </td>
                </tr>
                <tr>
                  <td>浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫</td>
                  <td>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
        </div>
        <div class="more" @click="show1 =!show1">放化疗完成后，其他后续内分泌方案见详情>></div>
        <div v-if="show1">
          <div class="jieshao">
            绝经前<br>
            初始治疗：
          </div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
               <tr>
                 <td>
                   <p>低复发风险患者（全满足）</p>
                   <p>1、淋巴结阴性</p>
                   <p>2、G1</p>
                   <p>3、T≤2cm</p>
                   <p>4、Ki-67低表达（≤30%）</p>
                 </td>
                 <td>
                   TAM5年
                 </td>
               </tr>
                <tr>
                  <td>
                    <p>满足以下任意条件者</p>
                    <p>1、G2或G3</p>
                    <p>2、淋巴结阳性</p>
                    <p>3、T>2cm</p>
                  </td>
                  <td>
                    OFS+TAM5年
                  </td>
                </tr>
                <tr>
                  <td>
                    淋巴结阳性>=4个患者
                  </td>
                  <td>
                    OFS+AI5年
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="jieshao">
            延长治疗：
          </div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    完成TAM5年初始治疗且耐受良好患者
                  </td>
                  <td>
                    <p>1、未绝经患者：延长TAM治疗至10年</p>
                    <p>2、绝经患者：序贯AI治疗5年</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    完成OFS+TAM5年初始治疗且耐受良好患者
                  </td>
                  <td>
                    绝经患者：序贯AI治疗
                  </td>
                </tr>
                <tr>
                  <td>
                    完成OFS+AI5年初始治疗且耐受良好患者
                  </td>
                  <td>
                    绝经患者：AI治疗
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="jieshao">
            绝经后<br>
            初始治疗：
          </div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    全部
                  </td>
                  <td>
                    <p>1、芳香化酶抑制剂（AI）5年</p>
                    <p>2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="jieshao">
            延长治疗：
          </div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    完成AI5年初始治疗且耐受良好低危患者
                  </td>
                  <td>
                    可停药
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>完成AI5年初始治疗且耐受良好患者，存在以下情况：</p>
                    <p>（1）淋巴结阳性</p>
                    <p>（2）G3</p>
                    <p>（3）其他需要行辅助化疗的危险因素，如KI-67＞30%</p>
                  </td>
                  <td>
                    继续AI治疗
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="jieshao">
          使用过新辅助化疗患者的可能用药及费用<br>
          如病理学未完全缓解的患者可选用：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td>T-DM1</td>
                <td>21</td>
                <td>45000</td>
                <td>65万</td>
                <td>否，全部自费</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="img2"><img src=".././../assets/images/tg2.png"> </div>
      </div>
      <div class="zhuliao" v-if="answer=='BBB'">
        <div class="tips">预估疾病进展及未来主要治疗药物和费用：</div>
        <div class="center">
          一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是很大的压力
        </div>
        <div class="jieshao">
          HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。<br>
          针对全切术后，Her2阳性，激素受体阳性的患者<br>
          未行辅助治疗患者的可能用药及费用<br>
          如腋窝淋巴结阳性：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>方案名称</th>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td rowspan="5">AC-THP</td>
                <td>多柔比星（多美素）</td>
                <td>21</td>
                <td>11200</td>
                <td>44800</td>
                <td>否，全部自费</td>
              </tr>
              <tr>
                <td>环磷酰胺（安道生）</td>
                <td>21</td>
                <td>440</td>
                <td>1760</td>
                <td>是，医保甲类</td>
              </tr>
              <tr>
                <td>序惯紫杉醇（克艾力）</td>
                <td>7</td>
                <td>11900</td>
                <td>142800</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
              <tr>
                <td>曲妥珠单抗</td>
                <td>21</td>
                <td>7600</td>
                <td>30400</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
              <tr>
                <td>帕妥珠单抗</td>
                <td>21</td>
                <td>4955</td>
                <td>24775</td>
                <td>是，医保乙类，部分自费</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="more" @click="show =!show">可能的其他治疗方案详情>></div>
        <div v-if="show">
          <div class="jieshao">化疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>方案推荐</th>
                  <th>预估每疗程花费</th>
                </tr>
                <tr>
                  <td rowspan="2">腋窝淋巴结阳性</td>
                  <td><popover title="AC-THP" :center=" ACTHPTips" /></td>
                  <td>3.5万元</td>
                </tr>
                <tr>
                  <td><popover title="TCbHP" :center=" TCbHPTips" /></td>
                  <td>3.3万元</td>
                </tr>
                <tr>
                  <td rowspan="2">
                    <p>腋窝淋巴结阳性，伴以下危险因素之一：</p>
                    <p>1、T>2cm</p>
                    <p>2、其他危险因素，如ER阴性</p>
                  </td>
                  <td><popover title="AC-TH" :center=" ACTHTips" /></td>
                  <td>2.9万元</td>
                </tr>
                <tr>
                  <td><popover title="TCbH" :center="TCbHTips" /></td>
                  <td>3.0万元</td>
                </tr>
                <tr>
                  <td>腋窝淋巴结阳性且T<2cm</td>
                  <td><popover title="TC+H" :center="TCbTips" /></td>
                  <td>2.7万元</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
          <div class="jieshao">放疗方案见下表：</div>
          <div class="table-box">
            <div class="table"style=" width: 580px;">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    <p>腋窝淋巴结清扫术后</p>
                    <p>1、T3—T4</p>
                    <p>2、腋窝淋巴结阳性</p>
                  </td>
                  <td>胸壁+区域淋巴结放疗</td>
                </tr>
                <tr>
                  <td>前哨淋巴结阳性，未行腋窝淋巴结清扫</td>
                  <td>
                    胸壁+包括腋窝在内的区域淋巴结放疗
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="huadong">滑动查看更多内容</div>
        </div>
        <div class="more" @click="show1 =!show1">放化疗完成后，其他后续内分泌方案见详情>></div>
        <div v-if="show1">
          <div class="jieshao">
            绝经前<br>
            初始治疗：
          </div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    <p>低复发风险患者（全满足）</p>
                    <p>1、淋巴结阴性</p>
                    <p>2、G1</p>
                    <p>3、T≤2cm</p>
                    <p>4、Ki-67低表达（≤30%）</p>
                  </td>
                  <td>
                    TAM5年
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>满足以下任意条件者</p>
                    <p>1、G2或G3</p>
                    <p>2、淋巴结阳性</p>
                    <p>3、T>2cm</p>
                  </td>
                  <td>
                    OFS+TAM5年
                  </td>
                </tr>
                <tr>
                  <td>
                    淋巴结阳性>=4个患者
                  </td>
                  <td>
                    OFS+AI5年
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="jieshao">
            延长治疗：
          </div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    完成TAM5年初始治疗且耐受良好患者
                  </td>
                  <td>
                    <p>1、未绝经患者：延长TAM治疗至10年</p>
                    <p>2、绝经患者：序贯AI治疗5年</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    完成OFS+TAM5年初始治疗且耐受良好患者
                  </td>
                  <td>
                    绝经患者：序贯AI治疗
                  </td>
                </tr>
                <tr>
                  <td>
                    完成OFS+AI5年初始治疗且耐受良好患者
                  </td>
                  <td>
                    绝经患者：AI治疗
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="jieshao">
            绝经后<br>
            初始治疗：
          </div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    全部
                  </td>
                  <td>
                    <p>1、芳香化酶抑制剂（AI）5年</p>
                    <p>2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="jieshao">
            延长治疗：
          </div>
          <div class="table-box">
            <div class="table">
              <table>
                <tr>
                  <th>患者情况</th>
                  <th>一级推荐</th>
                </tr>
                <tr>
                  <td>
                    完成AI5年初始治疗且耐受良好低危患者
                  </td>
                  <td>
                    可停药
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>完成AI5年初始治疗且耐受良好患者，存在以下情况：</p>
                    <p>（1）淋巴结阳性</p>
                    <p>（2）G3</p>
                    <p>（3）其他需要行辅助化疗的危险因素，如KI-67＞30%</p>
                  </td>
                  <td>
                    继续AI治疗
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="jieshao">
          使用过新辅助化疗患者的可能用药及费用<br>
          如病理学未完全缓解的患者可选用：
        </div>
        <div class="table-box">
          <div class="table"style=" width: 580px;">
            <table>
              <tr>
                <th>药品</th>
                <th>天/疗程</th>
                <th>预估每疗程费用</th>
                <th>预估4个疗程（84天）费用</th>
                <th>是否纳入医保，需自费</th>
              </tr>
              <tr>
                <td>T-DM1</td>
                <td>21</td>
                <td>45000</td>
                <td>65万</td>
                <td>否，全部自费</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="huadong">滑动查看更多内容</div>
        <div class="img2"><img src=".././../assets/images/tg2.png"> </div>
      </div>
      <div class="about">
        <div class="tips">关于乳腺癌专病保险：</div>
        <div class="center">
          乳腺癌患者如果想购买一般的重疾险和医疗险，都会被拒保。<br>
          若符合投保条件成功购买了这款专为乳腺癌患者设计的保险，如果万一未来疾病进展就医且符合理赔条件的情况下，则可获得相应保额的赔付，为您减轻高昂治疗费的负担。
        </div>
        <div class="img3"><img src=".././../assets/images/tg3.png"> </div>
        <div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {RadioGroup, Radio, Form, Field, Button, Icon, Toast} from 'vant';
import popover from './popover'
import {sessionState} from "../../api";
Vue.use(Form);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Button);
Vue.use(Icon);
export default {
  name: 'knowledge',
  data() {
    return {
      operation:"",
      her2:"",
      hormone:"",
      show:false,
      show1:false,
      answer:'',
      list:[],
      ACTTips:'蒽环类+环磷酰胺序贯多西他赛',
      ddACddtTips:'密集型蒽环类+环磷酰胺序贯密集型紫杉醇',
      ACTips:"蒽环类+环磷酰胺",
      TCTips:"多西他赛+环磷酰胺",
      TXTips:'紫衫类+卡培他滨',
      GTTips:'吉西他滨+紫杉醇',
      TPTips:'紫衫联合铂类',
      NPTips:"长春瑞滨联合铂类",
      GPTips:'吉西他滨+联合铂类',
      NXTips:'长春瑞滨+卡培他滨',
      ACTHPTips:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗+帕妥珠单抗",
      TCbHPTips:"多西他赛+卡铂+曲妥珠单抗+帕妥珠单抗",
      ACTHTips:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗",
      TCbHTips:'多西他赛+卡铂+曲妥珠单抗',
      TCbTips:"多西他赛+环磷酰胺+曲妥珠单抗",
      THPTips:"多西他赛+曲妥珠单抗+帕妥珠单抗",
      TXHTips:"紫衫类+卡培他滨+曲妥珠单抗"
    }
  },
   components: {
     popover
   },
  created() {
  },
  mounted() {
    this.getSessionState()
  },
  methods: {
    getSessionState(){
      sessionState(this.$store.getters.session).then((res)=>{
        console.log(res)
        let operation='A',her2='A',hormone='A'
        if(res.operation_method == '全切'){
          operation ='B'
        }
        if(res.her2 == '阳性'){
          her2 = 'B'
        }
        if(res.hormone_receptor == '阳性'){
          hormone = 'B'
        }

        this.answer = operation+her2+hormone
        this.show= false
        this.show1=false
      })},
    onSubmit(){
      if(this.operation ==''){
        Toast('请选择术式');
        return
      }
      if(this.her2 == ''){
        Toast('请选择Her2');
        return
      }
      if(this.hormone == ''){
        Toast('请选择激素受体');
        return
      }
      this.answer = this.operation+this.her2+this.hormone
      this.show= false
      this.show1=false
    },
    reset(){
      this.answer =''
    },
    onReset(){
      this.operation ='',
      this.her2 = '',
      this.hormone = '',
      this.answer =''
    }
  },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.knowledge{
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg.jpg');
  background-size:100% 100% ;
  position: fixed;
  overflow-y: scroll;
  padding: .3rem;
  .top{
    .wenti-box{
      background: #FFFFFF;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      padding: .4rem .3rem;
    }
  }
  .wenti-img{
    width: 100%;
    height: .34rem;
    img{
      width:.4rem;
      height: .34rem;
    }
  }
  .bottom{
    margin-top: .3rem;
    .knowledge-box{
      background: #FFFFFF;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      padding: .4rem .3rem;
      .title{
        padding-bottom: .12rem;
        font-size: .28rem;
        color: #131313;
      }
      .tijian{
        font-size: .24rem;
        line-height: .48rem;
        color: rgba(19, 19, 19, .5);
      }
      .mianze{
        .tips{
          margin: .22rem 0;
        }
        font-size: .24rem;
        color: #1675FF;
        border-bottom: 1px solid rgba(146, 157, 163, .2);
        padding-bottom: .3rem;
      }
      .yuhou{
        .tips{
          font-weight: 500;
          color: #131313;
          font-size: .28rem;
          margin: .3rem 0;
        }
        .center{
          width: 100%;
          height: 1.16rem;
          background: url("../../assets/images/tgbg.png") no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-weight: 500;
          font-size: .24rem;
          padding: .1rem;
          line-height: .5rem;
        }
        .list-box{
          .tips{
            color: rgba(76, 76, 76, .5);
            font-size: .24rem;
            line-height: .48rem;
            margin: .2rem 0;
          }
          .list{
            font-size: .24rem;
            li{
              line-height: .48rem;
              list-style-type:disc;
              margin-left: .3rem;
              span{
                width: .7rem;
                color: #1675FF;
                display: inline-block;
              }
            }
          }
        }
      }
      .img1{
        width: 100%;
        height: auto;
        text-align: center;
        margin-top: .4rem;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
    .zhuliao{
      background: #FFFFFF;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      padding: .4rem .35rem;
      margin: .4rem 0;
      width: 100%;
      .tips{
        font-weight: 500;
        color: #131313;
        font-size: .28rem;
        margin-bottom: .3rem;
      }
      .center{
        width: 100%;
        background: url("../../assets/images/tgbg.png") no-repeat;
        background-size: 100% 100%;
        color: #fff;
        font-weight: 500;
        font-size: .24rem;
        padding: .1rem;
        line-height: .5rem;
      }
      .jieshao{
        font-size: .24rem;
        color: #4C4C4C;
        margin: .2rem 0;
        line-height: .48rem;
      }
      .table-box{
       width: 100%;
        overflow-x: scroll;
        .table{
          table{
            width: 100%;
            font-size: .24rem;
            border-collapse:collapse;

            th{
              background: rgba(241, 248, 255, 1);
              padding: .3rem;
              border:1px rgba(146, 157, 163, .2) solid;
            }
            td{
              border:1px rgba(146, 157, 163, .2) solid;
              padding: .3rem;
              display:table-cell;
              vertical-align:middle
            }
          }
        }

      }
      .more{
        font-size: .24rem;
        color: #1675FF;
        margin: .4rem 0;
      }
      .img2{
        width: 100%;
        height: auto;
        text-align: center;
        margin-top: .4rem;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
    .about{
      background: #FFFFFF;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      padding: .4rem .35rem;
      .tips{
        font-weight: 500;
        color: #131313;
        font-size: .28rem;
        margin-bottom: .3rem;
      }
      .center{
        font-weight: 500;
        font-size: .24rem;
        line-height: .45rem;
        background: url("../../assets/images/tgbg2.png") no-repeat;
        background-size: 100% 100%;
        color: #fff;
        padding: .1rem;
      }
      .img3{
        width: 100%;
        height: auto;
        text-align: center;
        margin-top: .4rem;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
.mid{
  font-weight: 500;
  color: #C4C4C4;
  margin: .3rem 0;
}
.tab-box{
  table{
    width: 100%;
    border-collapse:collapse;
    margin-bottom:.2rem;
  }
  th{
    background: #F1F8FF;
    //line-height: .72rem;
    font-size: .24rem;
    color: #131313;
    padding: .2rem 0;
  }
  td{
    font-size: .24rem;
    padding: .28rem;
    border:1px solid rgba(146, 157, 163, .04);
    line-height: .4rem;
    p{
      line-height: .4rem;
    }
  }
}
.tab-title{
  font-size: .24rem;
  line-height: .6rem;
}
.show-btn{
  color: #1675FF;
  font-size: .24rem;
  text-align: center;
  margin-top: .3rem;
}
.frist-title{
  font-size: .28rem;
  font-weight: 500;
}
.font-bule{
  color: #1675FF;
}
.line-height{
  line-height: .7rem;
  font-weight: 500;
}
.huadong{
  width: 100%;
  text-align: center;
  font-size: .12rem;
  color: #C4C4C4;
  line-height: 30px;
}
</style>

