import { render, staticRenderFns } from "./diseaseKnowledge1.vue?vue&type=template&id=a0652bea&scoped=true&"
import script from "./diseaseKnowledge1.vue?vue&type=script&lang=js&"
export * from "./diseaseKnowledge1.vue?vue&type=script&lang=js&"
import style0 from "./diseaseKnowledge1.vue?vue&type=style&index=0&id=a0652bea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0652bea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/foryor-marketing_uam6/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a0652bea')) {
      api.createRecord('a0652bea', component.options)
    } else {
      api.reload('a0652bea', component.options)
    }
    module.hot.accept("./diseaseKnowledge1.vue?vue&type=template&id=a0652bea&scoped=true&", function () {
      api.rerender('a0652bea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/service/diseaseKnowledge1.vue"
export default component.exports